const footerList = [
  {
    title: "محصولات",
    items: [
      {
        name: "  دیتاسنتر ابری",
        link: "/vpc",
        type: "router",
      },
      {
        name: "سرور ابری",
        link: "/vps",
        type: "router",
      },
      {
        name: "پلتفرم ابری",
        link: "/paas",
        type: "router",
      },
      {
        name: "شبکه توزیع محتوا (CDN)",
        link: "/cloudguard/cdn",
        type: "router",
      },
      {
        name: " امنیت ابری",
        link: "/cloudguard/cloud-security",
        type: "router",
      },
      {
        name: "DNS ابری",
        link: "/cloudguard/dns",
        type: "router",
      },
      {
        name: "شبکه ابری",
        link: "/",
        type: "soon",
      },
      {
        name: "فضای ابری",
        link: "/",
        type: "soon",
      },
      {
        name: "سرویس‌های مدیریت شده",
        link: "/manageservices",
        type: "router",
      },
    ],
  },
  {
    title: "راهکارها",
    items: [
      {
        name: "مهاجرت ابری",
        link: "/migrate",
        type: "router",
      },
      {
        name: " ابر اختصاصی",
        link: "/privatecloud",
        type: "router",
      },
      {
        name: "ابر ترکیبی",
        link: "/privatecloud",
        type: "router",
      },
      {
        name: " راهکار بر اساس صنعت ",
        link: "/",
        type: "soon",
      },
      {
        name: "راهکارهای سازمانی ",
        link: "/",
        type: "soon",
      },
      {
        name: "راهکارهای استارتاپی  ",
        link: "/",
        type: "soon",
      },
      {
        name: "راهکارهای ملی",
        link: "/",
        type: "soon",
      },
    ],
  },
  {
    title: "قیمت‌ها",
    items: [
      {
        name: "شیوه قیمت‌گذاری",
        link: "",
        type: "link",
      },
      {
        name: "سرویس‌های رایگان زس",
        link: "/",
        type: "soon",
      },
      {
        name: "ماشین حساب",
        link: "/",
        type: "soon",
      },
    ],
  },
  {
    title: "ابر زس",
    items: [
      {
        name: " چطور شروع کنم؟",
        link: "/",
        type: "soon",
      },
      {
        name: "درباره زس",
        link: "/aboutus",
        type: "router",
      },
      {
        name: "راهنمای سرویس‌ها",
        link: "",
        type: "link",
      },
      {
        name: "سطوح خدماتی (SLA)",
        link: "",
        type: "link",
      },
      {
        name: "شرایط استفاده از خدمات",
        link: "",
        type: "link",
      },
      {
        name: "داستان مشتریان",
        link: "/",
        type: "soon",
      },
      {
        name: "شرکای ابری",
        link: "/",
        type: "soon",
      },
      {
        name: "سوالات متداول",
        link: "https://panel.xaascloud.org/login",
        type: "link",
      },
    ],
  },
  {
    title: "ارتباطات",
    items: [
      {
        name: "تماس با ما",
        link: "/contactus",
        type: "router",
      },
      {
        name: "ثبت تیکت",
        link: "https://panel.xaascloud.org/login",
        type: "link",
      },
      {
        name: "مشاوره تخصصی",
        link: "1",
        type: "popup",
      },
      {
        name: "ارتباط با واحد فروش",
        link: "1",
        type: "popup",
      },
      {
        name: " وبلاگ",
        link: "",
        type: "link",
      },
      {
        name: "فرصت‌های شغلی",
        link: "",
        type: "link",
      },
    ],
  },
];
const socials = [
  {
    name: "تلگرام زس",
    icon: "mdi-telegram",
    href: "https://t.me/XaaSCloud",
    img: "/telegram.svg",
    alt: "تلگرام ابر زَس",
    title: "تلگرام ابر زَس",
  },
  {
    name: "یوتیوب زس",
    icon: "mdi-youtube",
    href: "https://www.youtube.com/channel/UCqX1yrUoTImlfQy8zF2bLww",
    img: "/youtube.svg",
    alt: "یوتیوب ابر زَس",
    title: "یوتیوب ابر زَس",
  },
  {
    name: "توییتر زَس",
    icon: "mdi-twitter",
    href: "https://www.twitter.com/XaaS_ir",
    img: "/twitter.svg",
    alt: "توییتر ابر زَس",
    title: "توییتر ابر زَس",
  },
  {
    name: "لینکدین زَس",
    icon: "mdi-linkedin",
    href: "https://www.linkedin.com/company/xaas-cloud-computing",
    img: "/linkedin.svg",
    alt: "لینکدین ابر زَس",
    title: "لینکدین ابر زَس",
  },
  {
    name: "اینستاگرام زس",
    icon: "mdi-instagram",
    href: "https://www.instagram.com/xaascloud",
    img: "/instagram.svg",
    alt: "اینستاگرام ابر زَس",
    title: "اینستاگرام ابر زَس",
  },
];

const contacts = [
  {
    text: "تهران - سهروردی شمالی - هویزه غربی - ﭘﻠﺎک ۹۲ - برج آرین",
    icon: "/location.svg",
    href: "https://goo.gl/maps/tEuP4vR5xALSyPWd7",
    title: "موقعیت مکانی ابر زَس",
    alt: "آیکون موقعیت مکانی",
  },
  {
    text: " ﺗﻠﻔﻦ: ۴۹ ۰۷۸۱ ۹۱ ۰۲۱",
    icon: "/phone.svg",
    href: "tel:02191078149",
    title: "تماس با ابر زَس",
    alt: "آیکون تماس",
  },
];
const certificates = [
  {
    name: "انجمن صنفی کسب‌وکارهای اینترنتی",
    icon: "mdi-telegram",
    href: "javascript:;",
    img: "/anjoman.svg",
    title: "انجمن صنفی کسب‌وکارهای اینترنتی",
  },
  {
    name: "معاونت توسعه شرکت‌های دانش‌بنیان",
    icon: "mdi-telegram",
    href: "javascript:;",
    img: "/daneshbonyan.svg",
    title: "معاونت توسعه شرکت‌های دانش‌بنیان",
  },
  {
    name: "نماد اعتماد الکترونیکی",
    icon: "mdi-telegram",
    href: "https://trustseal.enamad.ir/?id=71526&amp;Code=usA8SYX7ayfkB90KkYTK",
    img: "/enamad.svg",
    title: "نماد اعتماد الکترونیکی",
  },
  {
    name: "سازمان نظام صنفی رایانه ای استان تهران",
    icon: "mdi-telegram",
    href: "https://tehran.irannsr.org/trade_unit/2406750/swd_id/104366/%D8%A7%D8%B9%D8%B6%D8%A7%DB%8C-%D8%AD%D9%82%D9%88%D9%82%DB%8C-%D8%B1%D8%A7%D9%87%DA%A9%D8%A7%D8%B1-%D8%A2%DB%8C%D9%86%D8%AF%D9%87-%D8%B2%D9%85%DB%8C%D9%86.html",
    img: "/nezamsenfi.svg",
    title: "سازمان نظام صنفی رایانه ای استان تهران",
  },
  {
    name: "جایزه فاب، فناوری اطلاعات برتر",
    icon: "mdi-telegram",
    href: "javascript:;",
    img: "/fab.svg",
    title: "جایزه فاب، فناوری اطلاعات برتر",
  },
  {
    name: "جامعه آزاد رایانش ابری ایران",
    icon: "mdi-telegram",
    href: "javascript:;",
    img: "/occc.svg",
    title: "جامعه آزاد رایانش ابری ایران",
  },

  {
    name: "شبکه آزمایشگاهی فناوری‌های راهبردی",
    icon: "mdi-telegram",
    href: "javascript:;",
    img: "/riasatjomhoori.svg",
    title: "شبکه آزمایشگاهی فناوری‌های راهبردی",
  },
];

export default { footerList, socials, contacts, certificates };
