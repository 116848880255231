import { render, staticRenderFns } from "./TheMenuRightItem.vue?vue&type=template&id=485752b7&scoped=true&"
import script from "./TheMenuRightItem.vue?vue&type=script&lang=js&"
export * from "./TheMenuRightItem.vue?vue&type=script&lang=js&"
import style0 from "./TheMenuRightItem.vue?vue&type=style&index=0&id=485752b7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "485752b7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tooltip: require('/app/components/partials/Tooltip.vue').default})
