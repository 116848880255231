
export default {
  name: "EmptyLayout",
  layout: "empty",
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      searchText: "",


    }
  },
  methods: {
    searchClick() {
      let text = this.searchText.replace(" ", "+");

      // window.open(`https://xaas.ir/blog/?s=${text}`,"_self")
    },
  },
  head() {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
    return {
      title,
    };
  },
};
